<template>
  <div>
    <customers-add
      :customer-add-sidebar-active.sync="customerAddSidebarActive"
      @refreshData="getCustomers"
    />

    <customers-edit
      v-if="updateShow"
      :customer-id="Number(customerId)"
      :customer-edit-sidebar-active.sync="customerEditSidebarActive"
      @refreshData="getCustomers"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              v-if="userData().role === 'admin' || userData().role === 'vendor'"
              variant="primary"
              @click="customerAddSidebarActive = true"
            >
              Nuevo
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar (nombre o nit)"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        hover
        responsive
        small
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidientes"
        :busy="tableIsBusy"
        :items="customersData"
        :fields="customersFields"
        :per-page="perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head(identidades)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(nit)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(nombre)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(tipo_desc)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn('tipo')"
          >{{ data.label }}
          </span>
        </template>

        <template #head(activo)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="userData().role === 'admin'"
          #cell(actions)="data"
        >
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="(data.item.activo === '0')"
                @click="updateCustomer(data.item.identidades)"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="(data.item.activo === '0')"
                @click="changeStatus(data.item.identidades, '1')"
              >
                <feather-icon icon="UserMinusIcon" />
                <span class="align-middle ml-50">Inactivar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="changeStatus(data.item.identidades, '0')"
              >
                <feather-icon icon="UserPlusIcon" />
                <span class="align-middle ml-50">Activar</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(identidades)="data">
          <b-link
            class="font-weight-bold text-info"
          >
            #{{ data.item.identidades }}
          </b-link>
        </template>

        <!-- Column: nit -->
        <template #cell(nit)="data">
          <b-link
            class="font-weight-bold text-secondary"
          >
            {{ data.item.nit }}
          </b-link>
        </template>

        <!-- Column: estado -->
        <template #cell(activo)="data">
          <b-badge
            :variant="(data.item.activo === '0') ? 'light-success' : 'light-danger'"
          >
            {{ (data.item.activo === '0') ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>

        <!-- Column: limite de credito -->
        <template #cell(limite_credito)="data">
          Q{{ formatCurrency(data.item.limite_credito) }}
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <!-- Pagination info -->
          <b-col
            v-if="customersMeta"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del {{ customersMeta.from }} al {{ customersMeta.to }} de {{ customersMeta.total }} registros</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="customersMeta"
              v-model="currentPage"
              :total-rows="customersMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'
import CustomersAdd from './CustomersAdd.vue'
import CustomersEdit from './CustomersEdit.vue'

export default {
  components: {
    vSelect,
    CustomersAdd,
    CustomersEdit,
  },

  data() {
    return {
      customerAddSidebarActive: false,
      customerEditSidebarActive: false,

      searchQuery: '',

      sortField: 'identidades',
      sortDesc: 'desc',

      perPage: 5,
      perPageOptions: [5, 10, 25, 50, 100, 'Todos'],
      currentPage: 1,

      customersFields: [
        { key: 'actions', label: '' },
        { key: 'identidades', label: 'Id' },
        { key: 'activo', label: 'Estado' },
        { key: 'nit', label: 'NIT' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'tipo_desc', label: 'Tipo de cliente' },
        { key: 'listap_desc', label: 'Lista de precios' },
        { key: 'forma_pago', label: 'Forma de pago' },
        { key: 'dias_credito', label: 'Días de crédito' },
        { key: 'limite_credito', label: 'Límite de crédito' },
        { key: 'vendedor', label: 'Vendedor' },
        { key: 'direccion', label: 'Dirección de cobro' },
        { key: 'direccion_factura', label: 'Dirección Factura' },
        { key: 'direccion_entrega', label: 'Dirección Entrega' },
        { key: 'contacto', label: 'Contacto' },
        { key: 'movil', label: 'Celular' },
        { key: 'tel', label: 'Teléfono para cobros' },
        { key: 'dias_cobro', label: 'Días de cobro' },
        { key: 'email', label: 'Correo' },
        { key: 'depto', label: 'Departamento' },
        { key: 'muni', label: 'Municipio' },
        { key: 'observaciones', label: 'Observaciones' },
        { key: 'fecha_creacion', label: 'Fecha de creación' },
        { key: 'creado_por', label: 'Creado por' },
      ],
      customersData: null,
      customersMeta: null,

      updateShow: false,
      customerId: 0,
      tableIsBusy: true,
    }
  },

  watch: {
    perPage() {
      this.tableIsBusy = true
      this.getCustomers()
    },
    currentPage() {
      this.tableIsBusy = true
      this.getCustomers()
    },
    searchQuery() {
      // esperar 2 segundos para no saturar el servidor
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.tableIsBusy = true
        this.getCustomers()
      }, 1000)
    },
  },

  created() {
    this.getCustomers()
  },

  methods: {
    userData() {
      return JSON.parse(localStorage.getItem('KultiverAuthUser'))
    },

    getCustomers() {
      if (this.perPage === 'Todos') {
        this.perPage = this.customersMeta.total
      }

      const filterArray = [
        { field: 'cliente', value: 1 },
      ]

      const urlEntities = (this.userData().role === 'admin') ? 'entities' : 'entities-user'

      axios
        .get(`accounting-system/${urlEntities}?filters=${JSON.stringify(filterArray)}&perPage=${this.perPage}&page=${this.currentPage}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
        .then(response => {
          this.tableIsBusy = false
          this.customersMeta = response.data.meta
          this.customersData = response.data.data
        })
        .catch(error => {
          this.tableIsBusy = false
          this.showErrors(error)
        })
    },

    updateCustomer(id) {
      this.updateShow = true
      this.customerEditSidebarActive = true
      this.customerId = id
    },

    changeStatus(id, status) {
      this.$bvModal
        .msgBoxConfirm('¿Deseas actualizar el estado del cliente?', {
          title: (status === '1') ? 'Activar cliente' : 'Inactivar cliente',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .put(`accounting-system/entities/${id}/status`)
              .then(response => {
                const msg = (status === '1') ? 'Cliente activado' : 'Cliente inactivado'
                this.makeToast('success', msg, `Se ha actualizado el cliente ${response.data.data.nombre}.`)
                this.getCustomers()
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    sortColumn(field) {
      if (this.sortField === field) {
        if (this.sortDesc === 'asc') {
          this.sortDesc = 'desc'
        } else {
          this.sortDesc = 'asc'
        }
      } else {
        this.sortField = field
        this.sortDesc = 'asc'
      }
      this.getCustomers()
    },

    formatCurrency(value) {
      if (!value) return '0' // Manejar valores nulos o indefinidos
      return new Intl.NumberFormat('es-GT', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
